import React from 'react';
import QueryString from 'query-string';
import { Router } from '@reach/router';
import Layout from './components/layout';

const { sessionToken } = QueryString.parse(window.location.search);
const query = window.location.search.substring(1).split('=')[0];
let queryResponse = window.location.search.substring(1).split('=')[1];;

function App() {
  return (
    <Router>
      <QRCodePage path="/" />
      <DownloadPage path="/download" />
    </Router>
  );
}

class QRCodePage extends React.PureComponent {
  render() {
    return (
      <Layout qrCodeValue={`${window.location.origin}/download${sessionToken ? `?sessionToken=${sessionToken}` : ''}`} />
    );
  }
}

class DownloadPage extends React.PureComponent {
  componentDidMount() {
    let host;
    switch (query) {
      case 'sessionToken':
        host = 'login';
        break;
      case 'missionId':
        host = 'mission';
        break;
      default:
        host = 'login';
        break;
    }
    if (sessionToken) {
      queryResponse = sessionToken;
    }
    const options = {
      url: `pleno://${host}/${queryResponse}`,
      fallbackAndroid: `https://play.google.com/store/apps/details?id=br.com.pleno`,
      fallbackIos: `https://apps.apple.com/br/app/pleno/id1500961287`,
    };

    this.deepLink(options);
  }

  deepLink(options) {
    const url = options.url || '';
    const ua = window.navigator.userAgent;
    const isMobile = {
      android: function () {
        return /Android/i.test(ua);
      },
      ios: function () {
        return /iPhone|iPad|iPod/i.test(ua);
      }
    };

    if (isMobile.ios() || isMobile.android()) {
      document.location = url;

      setTimeout(function () {
        window.open(url);
        document.location = isMobile.android() ? options.fallbackAndroid : options.fallbackIos;
      }, 3000);
    }
  }

  render() {
    return (
      <Layout qrCodeValue={`${window.location.origin}/download?${query}=${queryResponse}`} />
    );
  }
}

export default App;

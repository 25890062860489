import React from 'react';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';

class Layout extends React.PureComponent {
  render() {
    const { qrCodeValue } = this.props;
    console.log(qrCodeValue);
    return (
      <div className="layout">

        {!isMobile && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5">
                <div className="layout__left-image">
                  <img
                    className="layout__left-image__img"
                    src="/assets/img/ic_left_image.png"
                    alt="left"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="layout__content">

                  <div className="layout__content__inner">
                    <div className="layout__content__inner__title">
                      <img
                        className="layout__content__inner__title__img"
                        src="/assets/img/ic_title.png"
                        alt="title"
                      />
                    </div>

                    <div className="layout__content__inner__info">
                      <div className="layout__content__inner__info__item">
                        <div className="layout__content__inner__info__item__qr-code">
                          <QRCode
                            value={qrCodeValue}
                            size={193}
                          />
                        </div>
                      </div>
                      <div className="layout__content__inner__info__item layout__content__inner__info__item--centered">
                        <p className="layout__content__inner__info__item__data">
                          Bem-vindo ao Projet! Para acessar o app Pleno: Projet,{'\n'} 
                          basta utilizar a câmera do seu celular para ler o &nbsp;
                          <strong className="layout__content__inner__info__item__data__bold">
                            QR Code ao lado.
                          </strong>
                        </p>
                      </div>
                      <div className="layout__content__inner__info__item">
                        <div className="layout__content__inner__info__item__logo">
                          <img
                            className="layout__content__inner__info__item__logo__img"
                            src="/assets/img/ic_side_logo.png"
                            alt="title"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="layout__content__inner__steps">
                      <div className="layout__content__inner__steps__single">
                        <div className="layout__content__inner__steps__single__number">
                          <div className="layout__content__inner__steps__single__number__inner">1</div>
                        </div>
                        <div className="layout__content__inner__steps__single__desc">
                          Faça o download do aplicativo app <strong>Pleno: Projet</strong>
                        </div>
                      </div>

                      <div className="layout__content__inner__steps__single">
                        <div className="layout__content__inner__steps__single__number">
                          <div className="layout__content__inner__steps__single__number__inner">2</div>
                        </div>
                        <div className="layout__content__inner__steps__single__desc">
                          Faça o login no app com as <strong>suas credenciais.</strong>
                        </div>
                      </div>

                      <div className="layout__content__inner__steps__single">
                        <div className="layout__content__inner__steps__single__number">
                          <div className="layout__content__inner__steps__single__number__inner">3</div>
                        </div>
                        <div className="layout__content__inner__steps__single__desc">
                          Inicie a jornada de missões do seu <strong>projeto de vida</strong>!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="layout__content__inner__info__item">
                  <div className="layout__content__inner__info__item__logo">
                    <img
                      className="layout__content__inner__info__item__logo__img"
                      src="/assets/img/ic_side_logo.png"
                      alt="title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="layout__content__inner__info__item">
                  <div className="layout__content__inner__title">
                    <img
                      className="layout__content__inner__title__img"
                      src="/assets/img/ic_title.png"
                      alt="title"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="layout__content__inner__info__item">
                  <div className="layout__content__inner__info__item__mobile-download">
                    <p className="layout__content__inner__info__item__mobile-download__title">
                      Baixe o aplicativo nas lojas:
                    </p>
                    <a
                      className="layout__content__inner__info__item__mobile-download__link"
                      href="https://play.google.com/store/apps/details?id=br.com.pleno"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="layout__content__inner__info__item__mobile-download__link__img"
                        src="/assets/img/ic_android_download.png"
                        alt="android download"
                      />
                    </a>
                    <a
                      className="layout__content__inner__info__item__mobile-download__link"
                      href="https://apps.apple.com/br/app/pleno/id1500961287"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="layout__content__inner__info__item__mobile-download__link__img"
                        src="/assets/img/ic_ios_download.png"
                        alt="ios download"
                      />
                    </a>

                    <p className="layout__content__inner__info__item__mobile-download__refresh">
                      Já tem o app?&nbsp;
                      <a href="/download">
                        Toque aqui.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="layout__content__inner__steps">
                  <div className="layout__content__inner__steps__single">
                    <div className="layout__content__inner__steps__single__number">
                      <div className="layout__content__inner__steps__single__number__inner">1</div>
                    </div>
                    <div className="layout__content__inner__steps__single__desc">
                      Faça o download do aplicativo app <strong>Pleno: Projet</strong>
                    </div>
                  </div>

                  <div className="layout__content__inner__steps__single">
                    <div className="layout__content__inner__steps__single__number">
                      <div className="layout__content__inner__steps__single__number__inner">2</div>
                    </div>
                    <div className="layout__content__inner__steps__single__desc">
                      Faça o login no app com as <strong>suas credenciais.</strong>
                    </div>
                  </div>

                  <div className="layout__content__inner__steps__single">
                    <div className="layout__content__inner__steps__single__number">
                      <div className="layout__content__inner__steps__single__number__inner">3</div>
                    </div>
                    <div className="layout__content__inner__steps__single__desc">
                      Inicie a jornada de missões do seu <strong>projeto de vida</strong>!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="layout__content__inner__mobile-image">
                  <img
                    className="layout__content__inner__mobile-image__img"
                    src="/assets/img/img_mobile_left_img.png"
                    alt="center mobile"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Layout;
